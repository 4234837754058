import type { GetUserContextResponse, RunningContextResponse } from '@zoom/appssdk'
import noop from 'lodash/noop'

export const useZoomRunningContext = () => useState<RunningContextResponse>('zoom.runningContext')
export const useZoomUserContext = () => useState<GetUserContextResponse>('zoom.userContext')

/**
 * Check guest mode status
 * https://marketplace.zoom.us/docs/zoom-apps/guides/guest-mode/#elevating-the-app-experience
 */
export function useZoomIsInGuestMode () {
  const userContext = useZoomUserContext()
  return computed(() => {
    return [
      'unauthenticated',
      'authenticated'
    ].includes(userContext.value?.status)
  })
}

export function useZoomIsInMainClient () {
  const context = useZoomRunningContext()
  return computed(() => context.value?.context === 'inMainClient')
}

export function zoomOpenUrl (url: string) {
  zoomSdk.openUrl({
    url
  })
}

export function zoomPromptAuthorize () {
  zoomSdk.promptAuthorize()
}

export function initZoomRunningContext () {
  zoomSdk.onRunningContextChange(() => {
    setRunningContext().catch(noop)
    setUserContext().catch(noop)
  })

  setRunningContext().catch(noop)
}

export function initZoomUserContext () {
  zoomSdk.onMyUserContextChange(() => {
    initZoomAppSdk().catch(noop)
    setUserContext().catch(noop)
  })

  setUserContext().catch(noop)
}

export async function initZoomAppSdk () {
  await zoomSdk.config({
    version: '0.16',
    popoutSize: {
      width: 1280,
      height: 720
    },
    capabilities: [
      'openUrl',
      'shareApp',
      'getRunningContext',
      'onShareApp',
      'onRunningContextChange',
      'promptAuthorize',
      'getUserContext',
      'onMyUserContextChange'
    ]
  })
}

async function setRunningContext () {
  const runningContext = useZoomRunningContext()
  runningContext.value = await zoomSdk.getRunningContext()
}

async function setUserContext () {
  const userContext = useZoomUserContext()
  // `zoomSdk.getUserContext` would be pending if it's not in meeting
  // which means user context is undefined
  userContext.value = await zoomSdk.getUserContext()
}
