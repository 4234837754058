import { initZoomRunningContext, initZoomAppSdk, initZoomUserContext } from '~/composables/zoom'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:beforeMount', async () => {
    if (process.server) {
      return
    }

    await initZoomAppSdk()

    initZoomRunningContext()
    initZoomUserContext()
  })
})
