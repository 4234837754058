<template>
  <Body class="font-sans antialiased bg-white text-gray-900 dark:text-white dark:bg-zinc-900 border-gray-300 m-0">
    <div class="max-w-400px mx-auto px-2">
      <div class="w-full box-border mt-10 p-5 border border-gray-300 rounded-lg dark:border-gray-300/30">
        <div>
          <h1 class="text-xl m-0">
            <i class="i-fal-bomb mr-2" />
            <span class="align-middle">{{ error.statusMessage }}</span>
          </h1>

          <p
            v-if="error.data?.text"
            class="mt-4"
          >
            {{ error.data?.text }}
          </p>
        </div>

        <div class="mt-8 space-x-4 text-right">
          <ui-default-button
            class="text-base border-0"
            @click="backToHome"
          >
            Back To Home
          </ui-default-button>

          <ui-primary-button
            v-if="error.data?.class === 'FeatureLockedException'"
            class="text-base"
            @click="zoomOpenUrl(error.data?.subscribeUrl)"
          >
            Subscribe
          </ui-primary-button>
        </div>
      </div>
    </div>
  </Body>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import { zoomOpenUrl } from '~/composables/zoom'

defineProps<{
  error: NuxtError
}>()

const backToHome = () => {
  clearError({ redirect: '/' })
}

</script>
