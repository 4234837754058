import { default as ai_45assistant0ePEgKaVfZMeta } from "/opt/atlassian/pipelines/agent/build/pages/ai-assistant.vue?macro=true";
import { default as authorizeGkTbcTfsZoMeta } from "/opt/atlassian/pipelines/agent/build/pages/authorize.vue?macro=true";
import { default as callbackg32pTKPapzMeta } from "/opt/atlassian/pipelines/agent/build/pages/callback.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as logineaOYVOrhPDMeta } from "/opt/atlassian/pipelines/agent/build/pages/login.vue?macro=true";
import { default as _91id_931ZEtRI14ihMeta } from "/opt/atlassian/pipelines/agent/build/pages/video/[id].vue?macro=true";
import { default as youtube_45_91url_93qLtg4CYuX0Meta } from "/opt/atlassian/pipelines/agent/build/pages/video/youtube-[url].vue?macro=true";
export default [
  {
    name: ai_45assistant0ePEgKaVfZMeta?.name ?? "ai-assistant",
    path: ai_45assistant0ePEgKaVfZMeta?.path ?? "/ai-assistant",
    meta: ai_45assistant0ePEgKaVfZMeta || {},
    alias: ai_45assistant0ePEgKaVfZMeta?.alias || [],
    redirect: ai_45assistant0ePEgKaVfZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/ai-assistant.vue").then(m => m.default || m)
  },
  {
    name: authorizeGkTbcTfsZoMeta?.name ?? "authorize",
    path: authorizeGkTbcTfsZoMeta?.path ?? "/authorize",
    meta: authorizeGkTbcTfsZoMeta || {},
    alias: authorizeGkTbcTfsZoMeta?.alias || [],
    redirect: authorizeGkTbcTfsZoMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: callbackg32pTKPapzMeta?.name ?? "callback",
    path: callbackg32pTKPapzMeta?.path ?? "/callback",
    meta: callbackg32pTKPapzMeta || {},
    alias: callbackg32pTKPapzMeta?.alias || [],
    redirect: callbackg32pTKPapzMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logineaOYVOrhPDMeta?.name ?? "login",
    path: logineaOYVOrhPDMeta?.path ?? "/login",
    meta: logineaOYVOrhPDMeta || {},
    alias: logineaOYVOrhPDMeta?.alias || [],
    redirect: logineaOYVOrhPDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_931ZEtRI14ihMeta?.name ?? "video-id",
    path: _91id_931ZEtRI14ihMeta?.path ?? "/video/:id()",
    meta: _91id_931ZEtRI14ihMeta || {},
    alias: _91id_931ZEtRI14ihMeta?.alias || [],
    redirect: _91id_931ZEtRI14ihMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/video/[id].vue").then(m => m.default || m)
  },
  {
    name: youtube_45_91url_93qLtg4CYuX0Meta?.name ?? "video-youtube-url",
    path: youtube_45_91url_93qLtg4CYuX0Meta?.path ?? "/video/youtube-:url()",
    meta: youtube_45_91url_93qLtg4CYuX0Meta || {},
    alias: youtube_45_91url_93qLtg4CYuX0Meta?.alias || [],
    redirect: youtube_45_91url_93qLtg4CYuX0Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/video/youtube-[url].vue").then(m => m.default || m)
  }
]