<template>
  <ClientOnly>
    <Body class="font-sans antialiased bg-white text-gray-900 dark:text-white dark:bg-zinc-900 border-gray-300 m-0">
      <NuxtLayout>
        <NuxtLoadingIndicator
          :color="color"
        />
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </ClientOnly>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const color = `repeating-linear-gradient(to right,${config.public.colorBrandBlue} 0%,${config.public.colorBrandRed} 50%,${config.public.colorBrandGreen} 100%)`
</script>
