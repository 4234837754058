export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:mounted', () => {
    if (process.server) {
      return
    }

    const prefersDark = usePreferredDark()

    watchEffect(() => {
      if (prefersDark.value) {
        document.documentElement.classList.add('dark')
        return
      }

      document.documentElement.classList.remove('dark')
    })
  })
})
